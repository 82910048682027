import React from 'react'
import Layout from "../components/layout"
import ExhibitionNavBar from '../components/exhibitionNavBar'
import { graphql, Link} from 'gatsby'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES} from "@contentful/rich-text-types"
import './exhibition-future.css'
import SEO from "../components/seo"

export default function ExhibitionsFuture(props) {

  const options = {
                      renderNode: {
                        [INLINES.ASSET_HYPERLINK]: (node, children) => {
                          return <a href={node.data.target.file.url} className="">{children}</a>
                        },
                        [BLOCKS.EMBEDDED_ASSET]: node => {
                          return <img src={node.data.target.file.url} alt=""/>
                        },
                        [INLINES.ENTRY_HYPERLINK]: (node, children) => {

                          return <Link href={`/exhibitions/${node.data.target.slug}`} >{children}</Link>
                        }
                    }
                  }
    const {futureExhibitions} = props.data.allContentfulExhibitionFuture.edges[0].node          
    
    const exhibitions = futureExhibitions == null? "":renderRichText(futureExhibitions, options)

    return (
        <Layout>
          <SEO 
            title={`Future Exhibitions`}
          />
            <div>
            <ExhibitionNavBar bureauAudio={false}/>   
              <div className="future-exhibtion-cont">{exhibitions}</div>
            </div>
        </Layout>
    )
}

export const pageQuery = graphql
`
query MyQuery {
  allContentfulExhibitionFuture(filter: {order: {eq: 1}}) {
    edges {
      node {
        id
        futureExhibitions {
          raw
          references {
            ... on ContentfulExhibition {
              # contentful_id is required to resolve the references
              contentful_id
              title
              slug
            }
            ... on ContentfulAsset {
              contentful_id
              __typename
              file {
                url
                contentType
              }
            }
          }
        }
      }
    }
  }
}
  `


